import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Planning - Planning"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Planning`}</h1>
    <h2>{`Does my Pergola require planning permission?`}</h2>
    <p>{`As a general rule from the 1st October 2008, garden structures (including pergolas) do not require planning permission as long as the following points are followed:-`}</p>
    <ol>
      <li parentName="ol">{`No structure forward of the principle elevation fronting a highway.`}</li>
      <li parentName="ol">{`Must be single storey with a maximum eaves height of 2.5mtrs and a total height of less than or equal to 4mtrs for a pitched roof or 3mtrs for any other type of roof.`}</li>
      <li parentName="ol">{`Within 2mtrs of a boundary the maximum height cannot exceed 2.5mtrs.`}</li>
      <li parentName="ol">{`Garden structures must have no verandas, balconies or raised platforms.`}</li>
      <li parentName="ol">{`Where no more than 50% of the existing land around the ‘original house’ is already covered by garden buildings.`}</li>
      <li parentName="ol">{`Within the curtilage of a listed building planning permission must be sought.`}</li>
      <li parentName="ol">{`In Areas of Outstanding Natural Beauty, National Parks, World Heritage Sites, the maximum area to be covered by an outdoor structure, more than 20mtrs from the original house, must be limited to an area not exceeding 10sq mtrs. However, any buildings at the side of the property, within these nominated areas, will require planning permission.`}</li>
    </ol>
    <p>{`As local authorities may interpret these guidelines in different ways, it is always best to give your Local Planning Office a quick telephone call to discuss what you are intending to do, before you proceed, to ensure that you will meet the regulations for your own particular area.`}</p>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }, {
      filename: "Pergola wood treatment information",
      path: "/downloads/EN-Wolmanit_CX_enduser_022010.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      